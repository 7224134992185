@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "@fontsource/dm-sans";

body {
  font-family: "DM Sans" !important;
}

option:hover {
  background-color: #016eb8 !important;
  color: white;
}

.video-container {
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.overlay {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 35.09%,
      rgba(0, 0, 0, 0.35) 83.85%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45));
  opacity: 1;
}

.button {
  /* Styles for the button */
  position: relative;
}

.border {
  /* Styles for the button border */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border: 2px solid blue;
  border-radius: 50%;
  pointer-events: none;
  opacity: 0.5;
  transition: width 0.3s ease-in-out;
  animation-name: pulse;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  0% {
    width: 100%;
  }
  50% {
    width: 150%;
  }
  100% {
    width: 100%;
  }
}

.about-bottom {
  /* /background-image: url(./images/Site/generic_cta.png); */
  background-size: cover;
}

.hero-contact-l {
  /* */
  background-size: cover;
  background-repeat: no-repeat;
}

.solutions-banner {
  background-size: cover;
  background-repeat: no-repeat;
}

.cta {
  /* */
  background-size: cover;
}

.acc-banner {
  background-size: cover;
}
.partner-banner {
  background-size: cover;
}
.bg-bapaord-right {
  background: linear-gradient(
    162.8deg,
    #002e4d 19.14%,
    rgba(0, 46, 77, 0.8) 103.95%
  );
}

.form-partner {
  /* background-image: url(./images/Site/generic_cta.png); */
  background-size: cover;
}

.dd-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.au-banner {
  background-size: cover;
}

.careers-banner {
  background-size: cover;
}

.contactus-banner {
  background-size: cover;
}

.mobileSvg {
  transition: ease-in-out 0.1s;
}

.scroll-btn:hover {
  background-color: #016eb8;
}
.scroll-btn:hover svg path {
  fill: white;
}

.nav-slide:hover .nav-slide-rect {
  background-color: #016eb8;
  transition: ease-in-out 0.3s;
  color: white;
}
.nav-slide:hover .nav-slide-circle {
  fill: black;
  transition: ease-in-out 0.3s;
  stroke: #016eb8;
}
.nav-slide:hover .nav-slide-line {
  transition: ease-in-out 0.3s;
  fill: #016eb8;
}

.slick-dots {
  bottom: 30px !important;
}
/* Media Queries */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* bg-white/70 dropdown-menu absolute ${
  showCompany ? "" : "hidden"
} mx-auto mt-[45px] ml-[-32px] flex flex-col rounded-br-lg rounded-bl-lg`} */

.select-items {
  background-color: black;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
/* 
@keyframes borderCrawl {
  0% {
    border-top-width: 0;
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
  }
  25% {
    border-top-width: 3px;
    border-bottom-width: 3px;
    border-left-width: 0;
    border-right-width: 0;
  }
  50% {
    border-top-width: 3px;
    border-bottom-width: 3px;
    border-left-width: 0;
    border-right-width: 0;
  }
  75% {
    border-top-width: 3px;
    border-bottom-width: 3px;
    border-left-width: 3px;
    border-right-width: 0;
  }
  100% {
    border-top-width: 3px;
    border-bottom-width: 3px;
    border-left-width: 3px;
    border-right-width: 3px;
  }
}


.hero-au-btn:hover {
  animation: borderCrawl 5s linear;
} */

.slick-dots li button:before {
  color: white !important;
  font-size: 16px !important;
  width: 10px; /* Adjust the width to increase the size of the dots */
  height: 10px; /* Adjust the height to increase the size of the dots */
}

.ms-shadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.phoneSvg:hover circle {
  fill: #016eb8;
}

.phoneSvg:hover path {
  fill: #ffffff;
}

.mailSvg:hover circle {
  fill: #016eb8;
}

.mailSvg:hover path {
  fill: #ffffff;
}

.hero-auv {
  background: linear-gradient(180deg, white 50%, #002e4d 50%);
}

.slick-slider .slick-list {
  overflow: hidden !important;
}

.hero-client-slider .slick-slider .slick-list {
  overflow: visible !important;
}

.hero-client-slider .react-reveal .slick-slider .slick-list .slick-track {
  display: flex !important;
}
.hero-client-slider
  .react-reveal
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide {
  height: inherit !important;
}
.hero-client-slider
  .react-reveal
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide
  div {
  height: 100% !important;
}
